import React from 'react';
import {
  Added,
  Fixed,
  Improved,
  Removed,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="December 2020"
      subnav="release-notes">
      <div id="December2020" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          Big stuff is in the works, which usually means a light update. We're also a smidge early because we're taking some time off to recoop from it being March 292. See you in 2021!
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Improved>Usage guidelines for our{' '}<Link href="/patterns/empty-states">empty state</Link> patterns. (Thanks Bianca!)</Improved>
              <Fixed>
                An improper handler listed in the <Link href="/components/notifications/toast/code">toast</Link> snippet. (Thanks <Link href="https://github.com/ashlyn">Ashlyn</Link>!)
              </Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Figma Library" />

            <div className={styles.changesList}>
              <Removed>The documentation plugin in favor of Figma's new native documentation format.</Removed>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
